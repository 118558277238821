import Main from './main_screens/Main';
import Login from './main_screens/Login';
import { postAndWait, postAsync, convertAsyncResponseToObject } from './backend_access/APIAccess.js';
import React, { useState, useReducer, useEffect } from 'react';
function UIFramework(props) {

  const [isLogin, setIsLogin] = useState(props.loginScreen);

  const loginOK = () => {
    setIsLogin(false);
  }

  var searchParams = new URLSearchParams(window.location.search)
  var isAutoLogin = false;
  var autoLoginEmail = '';
  for (const [key, value] of searchParams) {
    var keyVar = key;
    var valueVar = value;
    if (keyVar === "application" && valueVar === "scanner") {
      isAutoLogin = true;
    } else if (keyVar === "autoLoginEmail") {
      autoLoginEmail = value;
    }
  }

  if (isAutoLogin && autoLoginEmail != '') {
    var request = {}
    request.Email = autoLoginEmail;
    request.Password = '';
    var resp = postAndWait('AutomaticLogin', request)
    if (resp.Result == false) {
      return <div>Unauthorised!</div>
    }
    window.state.apiToken = resp.Data;
    window.state.scanningOnlyMode = true;
    return <Main scanningOnlyMode={true} />
  }
  else {
    window.state.scanningOnlyMode = false;
    if (isLogin) {
      return <Login callback={loginOK} />
    } else {
      return <Main scanningOnlyMode={false} />
    }
  }
}

export default UIFramework;