import React, { useState, useReducer, useEffect } from 'react';
import { showError, showInfo } from '../components/PopupMessages.js';
import { postAndWait } from '../backend_access/APIAccess.js';
import TextButton from '../components/TextButton.js';
import LikelyScanners from '../components/LikelyScanners.js';
function AllocateScanLocations(props) {

  const [selectedAsignee, setSelectedAsignee] = useState(window.cache.LikelyScanners[0].Id)
  const [selectedAsigneeName, setSelectedAsigneeName] = useState(window.cache.LikelyScanners[0].Name)
  const [locationFilter, setLocationFilter] = useState('')
  const [filteredList, setFilteredList] = useState(window.cache.AllLocations)

  const handleFilterChange = (event) => {
    var newVal = event.target.value
    setLocationFilter(newVal)
    if (newVal == '') {
      setFilteredList(window.cache.AllLocations);
    }
    else {
      var filt = []
      for (var nn = 0; nn < window.cache.AllLocations.length; nn++) {
        if (window.cache.AllLocations[nn].Name.toUpperCase().includes(newVal)) {
          filt.push(window.cache.AllLocations[nn])
        }
      }
      setFilteredList(filt);
    }
  }

  const changeSelectedPerson = (obj) => {
    setSelectedAsignee(obj.Id)
    setSelectedAsigneeName(obj.Name)
  }

  const assignToUser = () => {

    if (filteredList.length === 0) {
      showError('No locations are currently selected to assign, based on the filter entered')
      return;
    }

    var req = {}
    req.Token = window.state.apiToken;
    req.UserId = selectedAsignee;

    var ids = []
    for (var nn = 0; nn < filteredList.length; nn++) {
      ids.push(filteredList[nn].Id)
    }

    req.LocationIds = ids;

    postAndWait('AssignLocationsToUser', req);

    showInfo('Locations assigned successfully');

    document.getElementById('txtFilt').value = '';
    setLocationFilter('');
    setFilteredList(window.cache.AllLocations);

  }

  return (
    <div style={{minWidth: "99vw"}}>
      <div style={{ margin: "auto" }}>
        <table width="100%">
          <tr>
            <td rowSpan="2" style={{ verticalAlign: 'top' }}>
              <div style={{ paddingLeft: "18px" }}>
                <h3>Users</h3>
                <LikelyScanners AllowEveryone={false} changeSelectedPerson={changeSelectedPerson} />
              </div>
            </td>
            <td style={{ verticalAlign: 'top', paddingLeft: "20px" }}>
              <div className="flex">
                <div>
                  <h3>Locations</h3>
                </div>
                <div style={{ paddingLeft: "70px", paddingTop: "12px" }}>
                  Filter: <input className="TextBox Narrow" id="txtFilt" onChange={handleFilterChange} /> <span className="FilterListCount">(Filter: {filteredList.length} locs.)</span>
                </div>
              </div>
              <div className="AllLocationList">
                {filteredList.map((f) => <span style={{ display: "inline-block" }} className="LocationAssigned">{f.Name}</span>)}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style={{ paddingRight: "600px" }}>
                <TextButton Callback={assignToUser} Caption={'Assign ' + filteredList.length + ' locations to ' + selectedAsigneeName} />
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    )

}

export default AllocateScanLocations;