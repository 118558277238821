import TextButton from '../components/TextButton.js';
import { postAndWait } from '../backend_access/APIAccess.js';
import { showError } from '../components/PopupMessages.js';
import { initialiseStateManager } from '../backend_access/StateManager.js';
import { useEffect } from 'react';
function Login(props) {

  const doLogin = () => {

    var un = document.getElementById('txtUN').value;
    var pw = document.getElementById('txtPW').value;

    let request = {};
    request.Email = un;
    request.Password = pw;

    let resp = postAndWait('Login', request)

    if (resp.Result == false) {
      showError(resp.ErrorMessage);
      return;
    }

    window.state.apiToken = resp.Data;  

    props.callback();

  }

  useEffect(() => {

    document.getElementById("txtUN")
      .addEventListener("keyup", function (event) {
        event.preventDefault();
        if (event.keyCode === 13) {
          doLogin();
        }
      });

    document.getElementById("txtPW")
      .addEventListener("keyup", function (event) {
        event.preventDefault();
        if (event.keyCode === 13) {
          doLogin();
        }
      });


  }, [])



  return (
    <>
      <div class="LoginFrame">
        <div class="LoginTitle">Storm Stock Control</div>
        <div class="LoginControlContainer">
          <div class="flex">
            <div class="LoginLabel">Username:</div>
            <div class="LoginBox"><input id="txtUN" class="TextBox Wide" /></div>
          </div>
          <div class="flex">
            <div class="LoginLabel">Password:</div>
            <div class="LoginBox"><input id="txtPW" type="password" class="TextBox Narrow" /></div>
          </div>
          <div class="LoginButtonContainer">
            <TextButton Caption="Login" Width={100} Callback={doLogin} />
          </div>
        </div>
        <div class="LoginImageHolder">
          <img src="storm.svg" width="200px" height="80px" class="HeaderImg" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <img src="scanner.svg" width="80px" height="80px" class="HeaderImg" />
        </div>
      </div>
    </>
  )
}

export default Login;