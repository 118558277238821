import React, { useState, useReducer, useEffect } from 'react';
import { postAndWait, postAsync, convertAsyncResponseToObject } from '../backend_access/APIAccess.js';
import { SubscriptionEvent } from '../backend_access/StateManager';
import Header from './Header';
import MainButton from '../components/MainButton';
import Stats from '../components/Stats';
import RecentScans from '../components/RecentScans';
import Location from '../scan_ui/Location';
import Items from '../scan_ui/Items';
import { showError, showInfo } from '../components/PopupMessages.js';
import '../stylesheet/styles.css';
import { loadInitialCache, forceImmediateMyLocationRefresh, forceImmediateAllocatedScanStatsRefresh } from '../backend_access/Cache';
import NewStocktake from '../child_screens/NewStocktake';
import AllocateScanLocations from '../child_screens/AllocateScanLocations';
import FinishStocktake from '../child_screens/FinishStocktake';
import Modal from 'react-modal'
import LikelyScanners from '../components/LikelyScanners';
import PartialStocktakeLocationPicker from '../child_screens/PartialStocktakeLocationPicker';
import SetMissingItemLocation from '../child_screens/SetMissingItemLocation';
import PurchaseOrder from '../scan_ui/PurchaseOrder.js';
import TextButton from '../components/TextButton.js';
function Main(props) {

  if (window.cache == null) {
    loadInitialCache();
  }

  const [updater, forceUpdate] = useReducer(x => x + 1, 0);
  const [appScreen, setAppScreen] = useState(props.scanningOnlyMode ? 'ScanOnlyLocation' : 'MainMenu');
  const [returnToMainVisible, setReturnToMainVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState('');
  const [scanMessage, setScanMessage] = useState('');
  const [scanError, setScanError] = useState('');
  const [reportingStocktakeIsMostRecent] = useState(true);

  const getSeedingReport = () => {

    var req = {}
    req.Token = window.state.apiToken;
    if (document.getElementById('txtExtraEmails').value != null && document.getElementById('txtExtraEmails').value != '') {
      req.AdditionalEmails = document.getElementById('txtExtraEmails').value
    }
    req.ReportId = 1;
    req.StocktakeId = window.cache.MostRecentFinishedStockTake.Id;

    var resp = postAndWait('GetReport', req)
    if (resp.Result) {
      showInfo('Your report should arrive by email shortly!')
    } else {
      showError('An error occurred: ' + resp.ErrorMessage)
    }

  }

  const getReconcilliationReport = () => {

    var req = {}
    req.Token = window.state.apiToken;
    if (document.getElementById('txtExtraEmails').value != null && document.getElementById('txtExtraEmails').value != '') {
      req.AdditionalEmails = document.getElementById('txtExtraEmails').value
    }
    req.ReportId = 2;
    req.StocktakeId = window.cache.MostRecentFinishedStockTake.Id;

    var resp = postAndWait('GetReport', req)
    if (resp.Result) {
      showInfo('Your report should arrive by email shortly!')
    } else {
      showError('An error occurred: ' + resp.ErrorMessage)
    }

  }
  
  const markMissingItemLocation = (obj) => {

    var request = {}
    request.Token = window.state.apiToken;
    request.StockUnitId = window.cache.AdminRescanLocations[0].MissingItemStockUnitId
    request.LocationId = window.state.missingItemScanLocation
    postAndWait('MarkItemFoundLocation', request)
    closeModal()

  }

  const moveToNewStockTake = () => {
    if (window.cache.StocktakeInProgress) {
      showError('A stocktake is already in progress. End it first.');
      return;
    }
    unsubscribeRefreshEvents()
    setAppScreen('NewStocktake')
    setReturnToMainVisible(true)
  }

  const moveToLocationAssignmentScreen = () => {
    if (!window.cache.StocktakeInProgress) {
      showError('There is no stocktake in progress right now!');
      return;
    }
    unsubscribeRefreshEvents()
    setAppScreen('LocationAssign')
    setReturnToMainVisible(true)
  } 

  const moveToEndStocktakeScreen = () => {
    if (!window.cache.StocktakeInProgress) {
      showError('There is no stocktake in progress right now!');
      return;
    }
    unsubscribeRefreshEvents()
    forceImmediateAllocatedScanStatsRefresh()
    setAppScreen('EndStocktake')
    setReturnToMainVisible(true)
  }

  const unsubscribeRefreshEvents = () => {
    window.state.unsubscribeFromEvent('Main', SubscriptionEvent.StockTakeInProgressUpdated);
    window.state.unsubscribeFromEvent('Main', SubscriptionEvent.StockTakeStatsUpdated);
    window.state.unsubscribeFromEvent('Main', SubscriptionEvent.AdminLocationsToScanUpdated);
    window.state.unsubscribeFromEvent('Main', SubscriptionEvent.MyLocationsToScanUpdated);
  }

  const returnToMain = () => {
    forceImmediateMyLocationRefresh()
    setAppScreen('MainMenu')
    window.state.appScreen = 'MainMenu'
    setReturnToMainVisible(false)
    window.state.scanScreenInput = ''
    window.state.lastScanTimeout = 0.0
    window.state.lastLocationName = ''
    window.state.lastLocationId = 0
    window.state.endScreenFilterList = []
  }

  const showUserSelectModal = (selectionCallback) => {
    window.state.modalConfirmAction = selectionCallback
    setModalMode('UserSelect')
    setShowModal(true)
  }

  const showPurchaseOrderModal = (confirmCallback) => {
    window.state.modalConfirmAction = confirmCallback
    setModalMode('POScan')
    setShowModal(true)
  }

  const showLocationPickerModal = (confirmCallback) => {
    window.state.modalConfirmAction = confirmCallback
    setModalMode('LocationPick')
    setShowModal(true)
  }

  const showMissingItemUserSelection = (confirmCallback) => {
    window.state.modalConfirmAction = confirmCallback
    setModalMode('UserSelectForMissingItem')
    setShowModal(true)
  }

  const showMissingItemLocationModal = (confirmCallback) => {
    window.state.modalConfirmAction = confirmCallback
    setModalMode('MissingItemLocation')
    setShowModal(true)
  }

  const scanOnlyMoveToItems = () => {
    setScanMessage('')
    setAppScreen('ScanOnlyItems')
  }

  const scanOnlySubmitSuccessful = (msg) => {
    window.state.scanModeMessage = msg;
    setAppScreen('ScanOnlyLocation')
  }

  const scanOnlyLocationCancelled = () => {
    window.state.scanModeMessage = '';
    window.state.scanScreenInput = '';
    setAppScreen('ScanOnlyLocation')
  }

  const scanOnlyMoveToPOScreen = () => {
    setAppScreen('ScanOnlyPO')
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const refresh = () => {
    forceUpdate();
    if (window.state.appScreen != 'MainMenu') {
      setTimeout(refresh, 150);
    }
  }

  useEffect(() => {

    return () => {
      window.state.unsubscribeFromEvent('Main', SubscriptionEvent.StockTakeInProgressUpdated);
      window.state.unsubscribeFromEvent('Main', SubscriptionEvent.StockTakeStatsUpdated);
      window.state.unsubscribeFromEvent('Main', SubscriptionEvent.AdminLocationsToScanUpdated);
      window.state.unsubscribeFromEvent('Main', SubscriptionEvent.MyLocationsToScanUpdated);
    }

  }, [])

  if (appScreen === 'MainMenu') {
    window.state.subscribeToEvent('Main', SubscriptionEvent.StockTakeInProgressUpdated, refresh);
    window.state.subscribeToEvent('Main', SubscriptionEvent.StockTakeStatsUpdated, refresh);
    window.state.subscribeToEvent('Main', SubscriptionEvent.MyLocationsToScanUpdated, refresh);
    window.state.subscribeToEvent('Main', SubscriptionEvent.AdminLocationsToScanUpdated, refresh);
  }

  var body;
  var stockTakeData;

  if (appScreen === 'ScanOnlyLocation') {
    body = <Location moveToItems={scanOnlyMoveToItems} scanMessage={window.state.scanModeMessage} scanError={window.state.scanModeError} />
  }
  else if (appScreen === 'ScanOnlyItems') {
    body = <Items scansSubmitSuccessful={scanOnlySubmitSuccessful} showPOItems={scanOnlyMoveToPOScreen} cancelLocation={scanOnlyLocationCancelled} />
  }
  else if (appScreen === 'ScanOnlyPO') {
    body = <PurchaseOrder cancelScans={scanOnlyMoveToItems} />
  }
  else if (appScreen === 'MainMenu') {

    //if the appScreen is MainMenu, we will render the main page

    if (window.cache.StocktakeInProgress) {

      var recentScanData;
      if (window.cache.OngoingStocktakeStats != null && window.cache.OngoingStocktakeStats.RecentScans != null && window.cache.OngoingStocktakeStats.RecentScans.length > 0) {
        recentScanData = window.cache.OngoingStocktakeStats.RecentScans.map((sc) => <RecentScans Key={sc.Username + sc.SerialNo} Who={sc.Username} Number={sc.Quantity} Location={sc.Location} Description={sc.Description} SerialNo={sc.SerialNo} />)
      } else {
        recentScanData = "Nothing scanned yet..."
      }

      if (window.cache.OngoingStocktakeStats != null) {
        stockTakeData = <><Stats />
          <h2>Recent scans: </h2>
          {recentScanData}</>
      } else {
        stockTakeData = <div style={{ padding: "30px" }}>Retrieving information about the <br />current stocktake, please wait...</div>
      }

    }
    else {
      stockTakeData = <><p>&nbsp;</p>
        <h1 style={{ fontSize: "22px" }}>
          <font color="#FF8888">There is no stocktake in progress right now.</font>
        </h1>
        <div>
          You can start a new stocktake using the button on the left. Before doing so, please ensure:
          <ul>
            <li>All warehouse operatives you intend to take part have their scanning devices ready and charged</li>
            <li>The warehouse is as quiet as it can possibly be</li>
            <li>You have the time and resources available to complete the size of stocktake you envisage in a reasonable timeframe</li>
          </ul>
        </div>
        </>
  
    }

    body = <table>
      <tr>
        <td width="30%" className="MainCell">
          <h2>
            Admin activities
          </h2>
          <div className="MainMenuStrip">
            <MainButton img="rack.svg" caption="New Stocktake" callback={moveToNewStockTake} />
            <MainButton img="assign.svg" caption="Assign Locations" callback={moveToLocationAssignmentScreen} />
            <MainButton img="time.svg" caption="End Stocktake" callback={moveToEndStocktakeScreen} />
          </div>
          <p>&nbsp;</p>
          <h2>
            Reporting
          </h2>
          <div>
            <div class="flex" style={{ color: "#DDDDDDFF", paddingTop: "8px" }}>
              <div style={{paddingRight: "10px"}}>Stocktake: </div>
              <div>
                {window.cache.MostRecentFinishedStockTake == null ? 'No stocktake completed' : window.cache.MostRecentFinishedStockTake.Name}
                {reportingStocktakeIsMostRecent ? <span style={{ display: "inline-block", paddingLeft: "24px", fontSize: "12px", color: "rgb(247 170 73)" }}>(This is the most recent completed stocktake)</span> : <></>}
                <div style={{paddingTop: "16px"} }>
                  <TextButton Caption="Select another stocktake" Width="200px" />
                </div>
              </div>
            </div>
            {window.cache.MostRecentFinishedStockTake != null ? 
              <div>
                <div style={{ fontSize: "14px", paddingTop: "28px" }}>Reports will always be emailed to you, you can add additional recipients below.<br/>Separate multiple email addresses with commas:<br />
                  <input style={{ width: "500px" }} id="txtExtraEmails" />
                </div>
                <div style={{ paddingTop: "20px" }}>
                  {window.cache.MostRecentFinishedStockTake.TypeId <= 3 ?
                    <TextButton Caption="Get Reconcilliation Report" Width="200px" Callback={() => getReconcilliationReport()} />
                    :
                    <TextButton Caption="Get Seeding Report" Width="200px" Callback={() => getSeedingReport()} />
                  }
                  </div>
                </div>
              :
              <></>
            }
          </div>
        </td>
        <td style={{ borderLeft: "solid 1px #555555", paddingLeft: "9px" }} width="70%" className="MainCell">
          <div style={{ height: "80vh", minHeight: "80vh", maxHeight: "80vh", overflowY: "auto", paddingLeft: "15px", paddingRight: "15px" }}>
            {stockTakeData}
          </div>
        </td>
      </tr>
    </table>

  }
  else if (appScreen === 'LocationAssign') {
    body = <AllocateScanLocations />
    window.state.appScreen = 'LocationAssign'
  }
  else if (appScreen === 'NewStocktake') {
    body = <NewStocktake ReturnToMain={returnToMain} ShowLocationPickScreen={showLocationPickerModal} />
    window.state.appScreen = 'NewStocktake'
  }
  else if (appScreen === 'EndStocktake') {
    body = <FinishStocktake ReturnToMain={returnToMain} ShowUserSelectModal={showUserSelectModal} ShowMissingItemLocationModal={showMissingItemUserSelection} />
    window.state.appScreen = 'EndStocktake'
  }

  return (
    <div style={{ width: "100vw", maxWidth: "100vw", minWidth: "100vw", height: "100vh", minHeight: "100vh", maxHeight: "100vh", overflow: "hidden" }}>
      <Header ReturnToMainVisible={returnToMainVisible} ReturnToMainCallback={returnToMain} />
      <div className="MainScreenBorder">
        {body}
      </div>
      <Modal isOpen={showModal} className={modalMode === 'UserSelect' ? 'SmallModal' : modalMode === 'POScan' ? 'WideModal' : 'LargeModal'} overlayClassName="ModalOverlay" >
        {showModal && modalMode === 'UserSelect' ? <LikelyScanners AllowEveryone={true} showTitle={true} Title={'Select scanner'} showConfirmButton={true} CloseModal={closeModal} /> :
          showModal && modalMode === 'LocationPick' ? <PartialStocktakeLocationPicker AreaId={window.state.areaForLocationPick} ClosePickScreenNoAction={() => closeModal()} /> :
            showModal && modalMode === 'MissingItemLocation' ? <SetMissingItemLocation CloseMissingItemScreenNoAction={() => closeModal()} /> :
              showModal && modalMode === 'UserSelectForMissingItem' ? <LikelyScanners AllowEveryone={false} showTitle={true} Title={'Select missing item hunter'} showConfirmButton={true} CloseModal={closeModal} /> :
          <></>}

      </Modal>
    </div>
  )
}

export default Main;