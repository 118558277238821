import React, { useState, useReducer, useEffect } from 'react';
import { postAndWait, postAsync, convertAsyncResponseToObject } from '../backend_access/APIAccess.js';
import { showError, showInfo, showYesNoQuestion } from '../components/PopupMessages.js';
import MainButton from '../components/MainButton';
import TextButton from '../components/TextButton.js';
import NumberPad from '../components/NumberPad.js';
import HumanInput from 'humaninput';
function Items(props) {

  const [canDeclareEmpty, setCanDeclareEmpty] = useState(window.state.scanModeLocationScanItems.ItemsWithSerials == null &&
    window.state.scanModeLocationScanItems.ItemsWithoutSerials == null && window.state.scanModeLocationScanItems.StockUnits == null);
  const [keyButtonText, setKeyButtonText] = useState('Type it');
  const [updater, forceUpdate] = useReducer(x => x + 1, 0);

  const [interchangeableId, setInterchangeableId] = useState(0);
  const [interchangeablePartNo, setInterchangeablePartNo] = useState('')

  const [question, setQuestion] = useState('')
  const [showQuestion, setShowQuestion] = useState(false)
  const [questionCallback, setQuestionCallback] = useState(null)
  const [questionText, setQuestionText] = useState('')
  const [questionSecondCallback, setQuestionSecondCallback] = useState(null)
  const [questionSecondText, setQuestionSecondText] = useState('')

  const emptyScan = () => {

    setQuestion('Are you certain that location ' + window.state.scanScreenLastLocationName + ' is empty?')
    setQuestionCallback(() => emptyLocationConfirm)
    setQuestionText('It is empty!')
    setQuestionSecondCallback(null)
    setShowQuestion(true)

  }

  const beginCancel = () => {

    setQuestion('Are you sure you wish to cancel scanning this location and return to the previous screen?')
    setQuestionCallback(() => finishCancel)
    setQuestionText('Yes, return')
    setQuestionSecondCallback(null)
    setShowQuestion(true)

  }

  const duplicatePOScan = () => {

    setQuestion('You have already scanned that PO in this location. Do you want to start again, or should I keep the data you have scanned and allow you to add to it?')
    setQuestionCallback(() => duplicatePOScanStartAgain)
    setQuestionText('Start PO again')
    setQuestionSecondCallback(() => duplicatePOContinue)
    setQuestionSecondText('Keep current data')
    setShowQuestion(true)

  }

  const duplicatePOScanStartAgain = () => {

    if (window.state.scanModeLocationScanItems != null && (window.state.scanModeLocationScanItems.ItemsWithSerials.length > 0 || window.state.scanModeLocationScanItems.ItemsWithoutSerials.length > 0)) {
      var existingWithSerial = []
      var existingWithoutSerial = []
      for (var nn = 0; nn < window.state.scanModeLocationScanItems.ItemsWithSerials.length; nn++) {
        if (window.state.scanModeLocationScanItems.ItemsWithSerials[nn].PONum != window.state.scanModePONumber) {
          existingWithSerial.push(window.state.scanModeLocationScanItems.ItemsWithSerials[nn])
        }
      }
      for (var nn = 0; nn < window.state.scanModeLocationScanItems.ItemsWithoutSerials.length; nn++) {
        if (window.state.scanModeLocationScanItems.ItemsWithoutSerials[nn].PONum != window.state.scanModePONumber) {
          existingWithoutSerial.push(window.state.scanModeLocationScanItems.ItemsWithoutSerials[nn])
        }
      }
      window.state.scanModeLocationScanItems.ItemsWithSerials = existingWithSerial;
      window.state.scanModeLocationScanItems.ItemsWithoutSerials = existingWithoutSerial;
    }
    window.state.keepPOItems = false;
    props.showPOItems();

  }

  const duplicatePOContinue = () => {

    window.state.keepPOItems = true;
    props.showPOItems();

  }

  const emptyLocationConfirm = () => {
    var req = {}
    req.Token = window.state.apiToken;
    req.LocationId = window.state.scanScreenlastLocationId;
    req.WasEmpty = true;
    req.Quantities = []; req.StockUnitIds = []; req.Sources = []; req.PONumbers = []; req.Serials = [];
    req.POItemIDs = []; req.AssetTags = []; req.POPartNos = []; req.POStockIds = [];
    var resp = postAndWait('SubmitScan', req);
    if (resp.Result) {
      window.state.scanModeLocationScanItems = {}
      props.scansSubmitSuccessful(window.state.scanScreenLastLocationName + ' submitted successfully!')
    }
  }

  const scanSubmit = () => {

    var res = window.state.scanScreenInput;
    var isSU = false;

    window.state.scanScreenInput = '';

    if (res.substring(0, 4).toLowerCase() == 'http') {
      var qmark = res.indexOf('?');
      res = res.substring(qmark + 3);
      isSU = true;
      var parse = '';
      for (var nn = 0; nn < res.length; nn++) {
        parse += res.substring(nn, nn + 1);
        if (parse.toLowerCase().endsWith('q=')) {
          parse = '';
        }
      }
      //showInfo(parse)
      res = parse;
    }

    if (res.substring(0, 2) != "SU") {
      if (isSU) {
        let req = { Token: window.state.apiToken, Id: res }
        postAsync("LookupStockUnit", req, submitItemScanReturn)
      } else {
        submitPOScan(res)
        window.state.itemLookupInProgress = false;
        window.state.scanScreenNonSUItem = res;
      }
    } else {
      if (window.cache.OngoingStocktakeStats.TypeId >= 4) {
        //This is a seeding stocktack and this is a stock unit...that can't be right
        showError("This stocktake is for seeding the database and you have just scanned a Stock Unit QR Code...that can't be right")
        window.state.itemLookupInProgress = false;
        return;
      }
      var id = res.substring(2);
      var req = {}
      req.Token = window.state.apiToken;
      req.Id = id;
      postAsync("LookupStockUnit", req, submitItemScanReturn)
    }

  }

  const submitItemScanReturn = (resp) => {

    window.state.itemLookupInProgress = false;

    var data = convertAsyncResponseToObject(resp);

    if (data.Result == null || data.Result == undefined || data.Result == false) {
      if (data.Result == null || data.Result == undefined) {
        showError('Bad request  ' + resp)
        return;
      } else {
        showError("An error occurred when accessing the backend of the system. Please report this to development. Error was: " + data.ErrorMessage);
        return;
      }
    }

    if (window.state.scanModeLocationScanItems == null) {
      window.state.scanModeLocationScanItems = {}
    }
    var existing = window.state.scanModeLocationScanItems;
    if (existing.ItemsWithSerials == null) {
      existing.ItemsWithSerials = [];
    }
    if (existing.ItemsWithoutSerials == null) {
      existing.ItemsWithoutSerials = [];
    }
    if (existing.StockUnits == null) {
      existing.StockUnits = [];
    }

    for (var nn = 0; nn < existing.StockUnits.length; nn++) {
      if (existing.StockUnits[nn].Id == data.Data.StockUnitId) {
        showError('You have already scanned that stock unit here!')
        return;
      }
    }

    if (data.Data.Interchangeable) {
      setInterchangeableId(data.Data.StockUnitId)
      setInterchangeablePartNo(data.Data.PartNo)
      makeScanQtyVisible();
      return;
    }

    var sus = [];
    var newSu = {}
    newSu.PartNo = data.Data.PartNo;
    newSu.NeedsSerial = !data.Data.Interchangeable;
    newSu.Serial = data.Data.SerialNo;
    newSu.Id = data.Data.StockUnitId;

    sus.push(newSu);
    if (existing.StockUnits.length > 0) {
      for (var nn = 0; nn < existing.StockUnits.length; nn++) {
        sus.push(existing.StockUnits[nn])
      }
    }

    existing.StockUnits = sus;
    window.state.scanModeLocationScanItems = existing;

    setCanDeclareEmpty(false)
    forceUpdate();

  }

  const submitNonSerialQty = (val) => {

    if (window.state.scanModeLocationScanItems == null) {
      window.state.scanModeLocationScanItems = {}
    }
    var existing = window.state.scanModeLocationScanItems;
    if (existing.ItemsWithSerials == null) {
      existing.ItemsWithSerials = [];
    }
    if (existing.ItemsWithoutSerials == null) {
      existing.ItemsWithoutSerials = [];
    }
    if (existing.StockUnits == null) {
      existing.StockUnits = [];
    }

    var sus = [];
    var newSu = {}
    newSu.PartNo = interchangeablePartNo;
    newSu.NeedsSerial = false;
    newSu.Count = val;
    newSu.Id = interchangeableId;

    sus.push(newSu);
    if (existing.StockUnits.length > 0) {
      for (var nn = 0; nn < existing.StockUnits.length; nn++) {
        sus.push(existing.StockUnits[nn])
      }
    }

    existing.StockUnits = sus;
    window.state.scanModeLocationScanItems = existing;

    makeScanQtyHidden()
    setCanDeclareEmpty(false)
    forceUpdate();

  }

  const submitPOScan = (val) => {
    let req = { Token: window.state.apiToken, Id: val }
    var resp = postAndWait('LookupPurchaseOrderItems', req)
    if (resp.Result) {
      if (resp.Data.length == 0) {
        window.communicationCode = 'BAD';
        showError("There was no error, but no items were loaded for that PO (" + val + "). Are you sure it exists in SAS3?")
      } else {
        window.state.scanModePONumber = val;
        window.state.scanModePOItems = resp.Data;
        if (window.state.scannedPOs.includes(val)) {
          duplicatePOScan();
        } else {
          window.state.scannedPOs.push(val)
          window.state.keepPOItems = false;
          props.showPOItems();
        }

      }
    } else {
      window.communicationCode = 'BAD';
      showError("An error occurred when attempting to load the items for that PO")
    }
  }

  const typePO = () => {
    if (document.getElementById('typeBox').style.visibility == 'collapse') {
      document.getElementById('typeBox').style.visibility = 'visible'
      setKeyButtonText('Scan')
    } else {
      document.getElementById('typeBox').style.visibility = 'collapse'
      setKeyButtonText('Type it')
    }
    
  }

  const finishCancel = () => {
    window.state.scanScreenInput = ''
    window.state.scanModeLocationScanItems = {}
    window.state.scannedPOs = []
    props.cancelLocation();
  }

  const beginConfirm = () => {
    showYesNoQuestion('Submit this as your final count of location ' + window.state.scanScreenLastLocationName + '?', finishSubmit);
  }

  const finishSubmit = () => {

    console.log('fs')

    var req = {}
    req.Token = window.state.apiToken;
    req.LocationId = window.state.scanScreenlastLocationId;

    var ids = []
    var qtys = []
    var sources = []
    var POs = []
    var POItemIDs = []
    var Serials = []
    var Assets = []
    var POPartNos = []
    var POStockIds = []

    for (var nn = 0; nn < window.state.scanModeLocationScanItems.ItemsWithSerials.length; nn++) {
      var i = window.state.scanModeLocationScanItems.ItemsWithSerials[nn];
      if (i.PONum != null) {
        sources.push("PO");
        qtys.push(1);
        POs.push(i.PONum);
        Serials.push(i.Serial);
        POPartNos.push(i.PartNo);
        POItemIDs.push(i.POItemId);
        ids.push(null); Assets.push(null); POStockIds.push(null);
      }
    }

    for (var nn = 0; nn < window.state.scanModeLocationScanItems.ItemsWithoutSerials.length; nn++) {
      var i = window.state.scanModeLocationScanItems.ItemsWithoutSerials[nn];
      if (i.PONum != null) {
        sources.push("PO");
        qtys.push(i.Count);
        POs.push(i.PONum);
        POPartNos.push(i.PartNo);
        POItemIDs.push(i.POItemId);
        ids.push(null); Assets.push(null); POStockIds.push(null); Serials.push(null);
      }
    }

    for (var nn = 0; nn < window.state.scanModeLocationScanItems.StockUnits.length; nn++) {
      var i = window.state.scanModeLocationScanItems.StockUnits[nn];
      ids.push(i.Id);
      sources.push("SU");
      if (i.NeedsSerial) {
        qtys.push(1)
      } else {
        qtys.push(i.Count)
      }
      POs.push(null); Serials.push(null); POItemIDs.push(null); Assets.push(null); POPartNos.push(null);
    }

    req.Quantities = qtys;
    req.StockUnitIds = ids;
    req.Sources = sources;
    req.PONumbers = POs;
    req.Serials = Serials;
    req.POItemIDs = POItemIDs;
    req.AssetTags = Assets;
    req.POPartNos = POPartNos;
    req.POStockIds = POStockIds;

    var resp = postAndWait('SubmitScan', req);
    if (resp.Result) {
      window.state.scanModeLocationScanItems = {}
      window.state.scanScreenInput = ''
      window.state.scannedPOs = []
      props.scansSubmitSuccessful(window.state.scanScreenLastLocationName + ' submitted successfully!')
    }

  }

  const keyUp = (event, key, code) => {

    if (code != 'Enter' && (code.length == 1 || code == "Equal")) {
      window.state.scanScreenInput += key;
    }
    if (code == 'Enter') {
      scanSubmit();
    }
  }

  if (window.state.humanInput == null) {

    eval('window.__VERSION__ = 0')
    var hi = new HumanInput(window)

    hi.off('keydown')
    hi.on('keydown', keyUp)
    hi.filter = (e) => { return true }

    window.state.humanInput = hi;
    window.state.locationLookupInProgress = false;

  } else {

    window.state.humanInput.off('keydown')
    window.state.humanInput.on('keydown', keyUp)
    window.state.humanInput.filter = (e) => { return true }

  }

  return (
    <div className="PortraitCenter">
      {showQuestion ?
        <div id="questionMsg" style={{
          position: "absolute", marginLeft: "auto", marginRight: "auto", border: "solid 1px #996421", background: "#131313FD",
          width: "92vw", left: "0", right: "0", height: "480px", top: "80", padding: "8px", zIndex: "10", color: "#FFFFFFFF",
          borderRadius: "10px"
        }}>
          <div style={{ textAlign: "center" }}>
            <p style={{ fontSize: "32px", fontWeight: "500" }}>Please Confirm</p>            
          </div>
          <div style={{ paddingLeft: "8px", paddingRight: "8px", fontWeight: "500" }}>
            <p>{question}</p>
            <p>&nbsp;</p>
          </div>
          {questionSecondCallback == null ?
            <div class="flex" style={{ width: "318px", marginLeft: "auto", marginRight: "auto" }}>
              <div>
                <TextButton Width="120px" IsCancel={true} Caption="Cancel" Callback={() => setShowQuestion(false)} />
              </div>
              <div style={{ paddingLeft: "12px" }}>
                <TextButton Width="120px" IsSuccess={true} Caption={questionText} Callback={questionCallback} />
              </div>
            </div>
            :
            <div style={{ width: "180px", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
              <div style={{ paddingBottom: "18px" }}>
                <TextButton Width="140px" Caption={questionText} Callback={questionCallback} />
              </div>
              <div style={{ paddingBottom: "18px" }}>
                <TextButton Width="140px" Caption={questionSecondText} Callback={questionSecondCallback} />
              </div>
              <div>
                <TextButton Width="140px" IsCancel={true} Caption="Cancel" Callback={() => setShowQuestion(false)} />
              </div>
            </div>
            }
        </div>
        : <></>
      }
      <div style={{ marginTop: "-20px" }}>
        <h2>Scanned location: {window.state.scanScreenLastLocationName}</h2>
      </div>
      <div className="flex">
        <div className="blink_me">Awaiting PO or Unit scan</div>
        <div style={{ marginTop: '-7px', paddingLeft: '16px' }}><TextButton Caption={keyButtonText} Callback={typePO} /></div>
      </div>
      <div id="typeBox" style={{ visibility: 'collapse', paddingTop: "10px", marginLeft: "-25px" }}>
        <NumberPad SubmitCallback={submitPOScan} ResetOnSubmit={true} />
      </div>
      <div id="interchangeableBox" style={{ visibility: 'collapse', paddingTop: "10px" }}>
        <span>Non-serial item, enter qty:</span>
        <NumberPad SubmitCallback={submitNonSerialQty} ResetOnSubmit={true} />
      </div>
      {canDeclareEmpty ?
        <div style={{ height: "285px", width: "300px", marginLeft: "88px" }}>
          <MainButton callback={emptyScan} caption="It's Empty!" img="pockets.svg" />
        </div> :
        <div style={{ height: "285px", overflowY: "auto" }}>
          <p><i>Scanned so far:</i></p>
          {window.state.scanModeLocationScanItems.ItemsWithSerials.map((i) =>
            <div>
              <div class="flex">
                <div style={{ fontSize: "13px", width: '120px' }}>PO: {i.PONum}</div>
                <div style={{ fontSize: "13px", width: '200px' }}>{i.PartNo}</div>
              </div>
              <div style={{ paddingBottom: "6px", marginTop: "-3px" }}><span style={{ color: 'rgb(247 170 73)', fontSize: "13px" }}>{i.Serial}</span></div>
            </div>)}
          {window.state.scanModeLocationScanItems.ItemsWithoutSerials.map((i) => 
            <div class="flex">
              <div style={{ width: '120px', fontSize: "13px" }}>PO: {i.PONum}</div>
              <div style={{ fontSize: "13px", width: '200px' }}>{i.PartNo} <span style={{ color: 'rgb(247 170 73)' }}>x {i.Count}</span></div>
            </div>)}
          {window.state.scanModeLocationScanItems.StockUnits.map((i) =>
            <div class="flex" style={{ paddingBottom: "8px" }}>
              <div style={{ width: '100px', fontSize: "13px" }}>SU{i.Id}</div>
              {i.NeedsSerial ?
                <div style={{ fontSize: "13px", width: '200px' }}>{i.PartNo}<br/><span style={{ color: 'rgb(247 170 73)' }}>{i.Serial}</span></div>
                :
                <div style={{ fontSize: "13px", width: '200px' }}>{i.PartNo}<br/><span style={{ color: 'rgb(247 170 73)' }}>Qty: {i.Count}</span></div>}
            </div>
            )}
        </div>
      }
      <div className="flex" style={{ paddingTop: "2px" }}>
        <div><TextButton IsCancel={true} Caption="Cancel scanning" Callback={beginCancel} /></div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div><TextButton IsSuccess={true} Caption="Confirm scans" Callback={beginConfirm} /></div>
      </div>
    </div>
  )

}

function makeScanQtyVisible() {
  document.getElementById('interchangeableBox').style.visibility = 'visible';
}

function makeScanQtyHidden() {
  document.getElementById('interchangeableBox').style.visibility = 'collapse';
}

export default Items;