function Stats(props) {

  return (

    <div style={{ fontSize: "20px" }}>
      <h2>Stocktake stats</h2>
      <table className="StatsTable" style={{ fontSize: "20px" }}>
        <tr>
          <td>
            Stocktake:
          </td>
          <td>
            {window.cache.OngoingStocktakeStats.Name}
          </td>
        </tr>
        <tr>
          <td>
            Work area:
          </td>
          <td>
            {window.cache.OngoingStocktakeStats.AreaName}
          </td>
        </tr>
      </table>
      <hr />
      <table className="StatsTable" style={{ fontSize: "17px" }}>
        <tr>
          <td>
            Locs. with zero scans:
          </td>
          <td>
            <b><font color="red">{window.cache.OngoingStocktakeStats.NotScanned}</font></b>
          </td>
          <td>
            &nbsp;&nbsp;
          </td>
          <td>
            Most scans by:
          </td>
          <td style={{ whiteSpace: "pre" }}>
            <b><font color="pink">{window.cache.OngoingStocktakeStats.MostScansName}</font></b>
          </td>
        </tr>
        <tr>
          <td>
            Locs. with one scan:
          </td>
          <td>
            <b><font color="orange">{window.cache.OngoingStocktakeStats.ScannedOnce}</font></b>
          </td>
          <td>
            &nbsp;&nbsp;
          </td>
          <td>
            Tot. items scanned:
          </td>
          <td>
            <b><font color="turquoise">{window.cache.OngoingStocktakeStats.ItemsScanned}</font></b>
          </td>
        </tr>
        <tr>
          <td>
            Locs. with two+ scans:
          </td>
          <td>
            <b><font color="green">{window.cache.OngoingStocktakeStats.ScannedTwice}</font></b>
          </td>
          <td>
            &nbsp;&nbsp;
          </td>
          <td>
            &nbsp;&nbsp;
          </td>
          <td>
            &nbsp;&nbsp;
          </td>
        </tr>
      </table>

    </div>

  )

}

export default Stats;