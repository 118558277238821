import React, { useState, useReducer, useEffect } from 'react';
import '../stylesheet/styles.css';

function SmallNumberPad(props) {

  const [enteredVal, setEnteredVal] = useState('0')

  const buttonClick = (stringNum) => {
    var val = enteredVal
    if (val == '0') {
      val = ''
    }
    val += stringNum
    setEnteredVal(val)
  }

  const delClick = () => {
    var val = enteredVal
    if (val.length == 1) {
      val = '0'
    } else {
      val = val.substring(0, val.length - 1);
    }
    setEnteredVal(val)
  }

  const submitClick = () => {
    props.SubmitCallback(enteredVal)
  }

  return (


    <>
      <table width="300">
        <tr>
          <td>
            <table width="150">
              <tr>
                <td width="33%" className="NumButtonHolder" onClick={() => buttonClick('7')}>
                  <span className="SmallNumButton">7</span>
                </td>
                <td width="33%" className="NumButtonHolder" onClick={() => buttonClick('8')}>
                  <span className="SmallNumButton">8</span>
                </td>
                <td width="33%" className="NumButtonHolder" onClick={() => buttonClick('9')}>
                  <span className="SmallNumButton">9</span>
                </td>
              </tr>
              <tr>
                <td className="NumButtonHolder" onClick={() => buttonClick('4')}>
                  <span className="SmallNumButton">4</span>
                </td>
                <td className="NumButtonHolder" onClick={() => buttonClick('5')}>
                  <span className="SmallNumButton">5</span>
                </td>
                <td className="NumButtonHolder" onClick={() => buttonClick('6')}>
                  <span className="SmallNumButton">6</span>
                </td>
              </tr>
              <tr>
                <td className="NumButtonHolder" onClick={() => buttonClick('1')}>
                  <span className="SmallNumButton">1</span>
                </td>
                <td className="NumButtonHolder" onClick={() => buttonClick('2')}>
                  <span className="SmallNumButton">2</span>
                </td>
                <td className="NumButtonHolder" onClick={() => buttonClick('3')}>
                  <span className="SmallNumButton">3</span>
                </td>
              </tr>
              <tr>
                <td className="NumButtonHolder" onClick={() => delClick()}>
                  <img width="40px" height="32px" src="backspace.png" />
                </td>
                <td className="NumButtonHolder" onClick={() => buttonClick('0')}>
                  <span className="SmallNumButton">0</span>
                </td>
                <td className="NumButtonHolder" onClick={() => submitClick()}>
                  <img width="40px" height="32px" src="numenter.png" />
                </td>
              </tr>
            </table>
          </td>
          <td>
            <input className="SmallDigitalBox" id="number" value={enteredVal} />
          </td>
        </tr>
      </table>
      


    </>



  )

}

export default SmallNumberPad;