import React, { useState, useReducer, useEffect } from 'react';
import { showError, showInfo } from '../components/PopupMessages.js';
import { postAndWait } from '../backend_access/APIAccess.js';
import TextButton from '../components/TextButton.js';

function LikelyScanners(props) {

  const [userList, setUserList] = useState(window.cache.LikelyScanners)
  const [selectedAsignee, setSelectedAsignee] = useState(window.cache.LikelyScanners[0].Id)
  const [selectedAsigneeName, setSelectedAsigneeName] = useState(window.cache.LikelyScanners[0].Name)
  const [loaded, setIsLoaded] = useState(false)

  const changeSelectedPerson = (obj) => {
    setSelectedAsignee(obj.Id)
    setSelectedAsigneeName(obj.Name)
    if (props.changeSelectedPerson != null) {
      props.changeSelectedPerson(obj)
    }
  }

  const selectionMadeCallback = () => {
    var obj = {};
    obj.Id = selectedAsignee;
    obj.Name = selectedAsigneeName;
    window.state.modalConfirmAction(obj)
    props.CloseModal()
  }

  if (!loaded && props.AllowEveryone) {
    var list = []
    var everyone = {
      Id: -1,
      Name: 'ANYBODY'
    }
    list.push(everyone)
    list.push(...window.cache.LikelyScanners)
    setUserList(list)
    setIsLoaded(true)
    setSelectedAsignee(-1)
    setSelectedAsigneeName('ANYBODY')

  }

  return (
    <div>
      { props.showTitle ? <h2>{props.Title}</h2> : <></> }
      <div class="LocationAsigneeDiv">
        {userList.map((lu) => <div onClick={() => changeSelectedPerson(lu)} className={lu.Id == selectedAsignee ? 'ToggleItemEnabled TypeSpan' : 'ToggleItemDisabled TypeSpan'}>{lu.Name}</div>)}
      </div>
      {props.showConfirmButton ? <div className="ModalConfirmContainer"><TextButton Caption="Cancel operation" Callback={props.CloseModal} />&nbsp;&nbsp;<TextButton Caption="Confirm selection" Callback={() => selectionMadeCallback()} /></div> : <></>}
    </div>
  )

}

export default LikelyScanners;