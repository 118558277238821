//Setup an 'enum' for all valid subscription types
export class SubscriptionEvent {

  static StockTakeInProgressUpdated = new SubscriptionEvent("StockTakeInProgressUpdated");
  static StockTakeStatsUpdated = new SubscriptionEvent("StockTakeStatsUpdated");
  static MyLocationsToScanUpdated = new SubscriptionEvent("MyLocationsToScanUpdated");
  static AdminLocationsToScanUpdated = new SubscriptionEvent("AdminLocationsToScanUpdated");
  static ReconcilliationDataUpdated = new SubscriptionEvent("ReconcilliationDataUpdated");
  static POScanScreenCancelled = new SubscriptionEvent("POScanScreenCancelled");
  static SerialScanScreenCancelled = new SubscriptionEvent("SerialScanScreenCancelled");
  static StocktakeStatusUpdated = new SubscriptionEvent("StocktakeStatusUpdated");

  constructor(name) {
    this.Name = name
  }

}

//Setup an 'enum' for all valid callback types
export class CallbackType {

  static LoginOK = new CallbackType("LoginOK");

  constructor(name) {
    this.Name = name
  }

}

//Setup an 'enum' for all valid properties that we can set
export class PropertyName {

  static LoggedIn = new PropertyName("LoggedIn");
  static APIToken = new PropertyName("APIToken");
  static StockTakeID = new PropertyName("StockTakeID");

  constructor(name) {
    this.Name = name
  }

}

export class Dictionary {

  static ScannableLocations = new Dictionary("ScannableLocations");
  static SKUs = new Dictionary('SKUs');

  constructor(name) {
    this.Name = name;
  }

}

export function initialiseStateManager() {

  let state = {
    apiToken: '',
    currentScreen: 'Main',
    screenPrimaryAction: null,
    subscriptions: [],
    callbacks: [],
    properties: [],
    dictionaries: [],
    registerCallback: registerCallback,
    invokeCallback: invokeCallback,
    subscribeToEvent: subscribeToEvent,
    unsubscribeFromEvent: unsubscribeFromEvent,
    fireEvent: fireEvent,
    updateObjectProperty: updateObjectProperty,
    addOrUpdateProperty: addOrUpdateProperty,
    isPropertyMissing: isPropertyMissing,
    getProperty: getProperty,
    addDictionary: addDictionary,
    addItemToDictionary: addItemToDictionary,
    getDictionary: getDictionary,
    getItemFromDictionary: getItemFromDictionary,
    replaceItemInDictionary: replaceItemInDictionary,
    appScreen: 'Login',
    modalConfirmAction: null,
    lastPOScanId: 0,
    lastPOScan: null,
    lastPOScanScreenData: null,
    lastSerialScanSerial: '',
    lastSerialScan: null,
    lastSerialScanScreenData: null,
    areaForLocationPick: 0,
    locationsSelected: null,
    scanScreenInput: '',
    scanScreenLastLocationName: '',    
    scanScreenlastLocationId: 0,
    scanScreenItemDescription: '',
    scanScreenItemSerial: '',
    scanScreenItemQty: 0,
    scanScreenStockUnitId: 0,
    scanScreenItemsScanned: [],
    scanScreenNonSUItem: '',
    locationLookupInProgress: false,
    itemLookupInProgress: false,
    endScreenFilterList: [],
    humanInput: null,
    missingItemToFind: null,
    missingItemScanLocation: 0,
    nextScanIsMissingItemHunt: false,
    scanModeMessage: '',
    scanModeError: '',
    scanModePONumber: 0,
    scanModePOItems: [],
    scanModeLocationScanItems: {},
    scanningOnlyMode: false,
    keepPOItems: false,
    scannedPOs: [],
    scanScreenStartingData: null
  };
  window.state = state;

}

function addDictionary(dictName) {

  if (dictName instanceof Dictionary) {
    var filtered = window.state.dictionaries.filter(function (value, index, arr) { return value.Name != dictName.Name; });
    var newDict = {};
    newDict.Name = dictName.Name;
    newDict.Keys = [];
    newDict.Items = [];
    filtered.push(newDict);
    window.state.dictionaries = filtered;
  }
  else {
    console.log(dictName + " is not a valid dictionary");
  }

}

function getDictionary(dictName) {
  if (dictName instanceof Dictionary) {
    for (var nn = 0; nn < window.state.dictionaries.length; nn++) {
      if (window.state.dictionaries[nn].Name = dictName.Name) {
        return window.state.dictionaries[nn];
      }
    }
    return null;
  }
  else {
    console.log(dictName + " is not a valid dictionary");
  }
}

function getItemFromDictionary(dictName, itemKey) {
  if (dictName instanceof Dictionary) {
    var filtered = window.state.dictionaries.filter(function (value, index, arr) { return value.Name == dictName.Name; });
    if (filtered.length == 1) {
      for (var nn = 0; nn < filtered[0].Keys.length; nn++) {
        if (filtered[0].Keys[nn] == itemKey) {
          return filtered[0].Items[nn];
        }
      }
      return null;
    }
    else {
      console.log(dictName + " is not currently loaded");
    }
  }
  else {
    console.log(dictName + " is not a valid dictionary");
  }

}

function addItemToDictionary(dictName, itemKey, item) {
  if (dictName instanceof Dictionary) {
    for (var nn = 0; nn < window.state.dictionaries.length; nn++) {
      if (window.state.dictionaries[nn].Name == dictName.Name) {
        var filteredKeys = window.state.dictionaries[nn].Keys.filter(function (value, index, arr) { return value == itemKey; });
        if (filteredKeys.length == 0) {
          window.state.dictionaries[nn].Keys.push(itemKey);
          window.state.dictionaries[nn].Items.push(item);
          return;
        }
        else {
          console.log(itemKey + " is already present in the dictionary");
          return;
        }

      }
    }
    console.log(dictName + " is not currently loaded");
  }
  else {
    console.log(dictName + " is not a valid dictionary");
  }
}

function replaceItemInDictionary(dictName, itemKey, newItem) {

  if (dictName instanceof Dictionary) {
    for (var nn = 0; nn < window.state.dictionaries.length; nn++) {
      if (window.state.dictionaries[nn].Name == dictName.Name) {
        for (var nKey = 0; nKey < window.state.dictionaries[nn].Keys.length; nKey++) {
          if (window.state.dictionaries[nn].Keys[nKey] == itemKey) {
            window.state.dictionaries[nn].Items[nKey] = newItem;
            return;
          }
        }
        console.log('Key ' + itemKey + ' is not in dictionary ' + dictName);
      }
    }
    console.log(dictName + " is not currently loaded");
  }
  else {
    console.log(dictName + " is not a valid dictionary");
  }

}

function addOrUpdateProperty(propName, propVal) {

  if (propName instanceof PropertyName) {
    var filtered = window.state.properties.filter(function (value, index, arr) { return value.Name == propName.Name; });
    if (filtered.length == 1) {
      filtered = window.state.properties.filter(function (value, index, arr) { return value.Name != propName.Name; });
      let newProp = { Name: propName.Name, Value: propVal };
      filtered.push(newProp);
      window.state.properties = filtered;
    }
    else {
      var full = window.state.properties;
      let newProp = { Name: propName.Name, Value: propVal };
      full.push(newProp);
      window.state.properties = full;
    }
  }
  else {
    console.log(propName + " is not a valid property");
  }

}


function getProperty(propName) {

  if (propName instanceof PropertyName) {
    var filtered = window.state.properties.filter(function (value, index, arr) { return value.Name == propName.Name; });
    if (filtered.length == 1) {
      return filtered[0].Value;
    }
    else {
      console.log("getProperty says there was not exactly one property with the name " + propName.Name);
    }
  }
  else {
    console.log("getProperty says it has been passed an invalid property name");
  }


}

function isPropertyMissing(propName) {
  if (propName instanceof PropertyName) {
    var filtered = window.state.properties.filter(function (value, index, arr) { return value.Name == propName.Name; });
    return (filtered.length == 0);
  }
  else {
    console.log(propName + " is not a valid property");
  }

}

function updateObjectProperty(stateProperty, fieldName, newVal) {
  stateProperty[fieldName] = newVal;
}

function registerCallback(callbackName, callback) {

  if (callbackName instanceof CallbackType) {
    let callb = { Name: callbackName.Name, Callback: callback };
    var existing = window.state.callbacks.filter(function (value, index, arr) { return value.Name != callbackName.Name; });
    existing.push(callb);
    window.state.callbacks = existing;
  }
  else {
    console.log(callbackName + " is not a valid callback type");
  }

}

function invokeCallback(callbackName) {
  if (callbackName instanceof CallbackType) {
    var candidates = window.state.callbacks.filter(function (value, index, arr) { return value.Name == callbackName.Name; });
    if (candidates.length == 1) {
      candidates[0].Callback();
    }
  }
  else {
    console.log(callbackName + " is not a valid callback type");
  }
}

function subscribeToEvent(pageName, event, callback) {

  if (event instanceof SubscriptionEvent) {

    var filtered = window.state.subscriptions.filter(function (value, index, arr) { return value.Page == pageName && value.Event == event.Name; });

    if (filtered.length == 0) { //if more than 0 then that page is already subscribed to this event
      let subscribe = { Page: pageName, Event: event.Name, Callback: callback };
      var existing = window.state.subscriptions;
      existing.push(subscribe);
      window.state.subscriptions = existing;
    }

  }
  else {
    console.log(event + " is not a valid event to subscribe to, was requested by page: " + pageName);
  }

}

function unsubscribeFromEvent(pageName, event) {

  if (event instanceof SubscriptionEvent) {
    var filtered = window.state.subscriptions.filter(function (value, index, arr) { return value.Page != pageName || value.Event != event.Name; });
    window.state.subscriptions = filtered;
  }
  else {
    console.log(event + " is not a valid event to unsubscribe from, was requested by page: " + pageName);
  }

}

function fireEvent(event) {

  if (event instanceof SubscriptionEvent) {
    var filtered = window.state.subscriptions.filter(function (value, index, arr) { return value.Event == event.Name; });
    for (var nn = 0; nn < filtered.length; nn++) {
      filtered[nn].Callback();
    }
  }
  else {
    console.log(event + " is not a valid event to fire");
  }

}