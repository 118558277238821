import React from 'react';
import ReactDOM from 'react-dom/client';
import './stylesheet/styles.css';
//import Login from './Login';
import UIFramework from './UIFramework';
import { initialiseStateManager } from './backend_access/StateManager';

initialiseStateManager();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<UIFramework loginScreen="true" />);
