import React, { useState, useReducer, useEffect } from 'react';
import { postAndWait, postAsync, convertAsyncResponseToObject } from '../backend_access/APIAccess.js';
import TextButton from '../components/TextButton.js';
import Stats from '../components/Stats.js';
import { showError, showInfo, showYesNoQuestion } from '../components/PopupMessages.js';
import { forceImmediateStatusRefresh, forceImmediateReconcilliationDataRefresh } from '../backend_access/Cache';
import { SubscriptionEvent } from '../backend_access/StateManager';
function FinishStocktake(props) {

  const [filteredRecList, setFilteredRecList] = useState(window.cache.RecData)
  const [filterScanMode, setFilterScanMode] = useState('All')
  const [selectedRecData, setSelectedRecData] = useState(0)
  const [selectedRecStats, setSelectedRecStats] = useState(null)
  const [updater, forceUpdate] = useReducer(x => x + 1, 0);
  const [awaitingIssueSubmission, setAwaitingIssueSubmission] = useState(false)
  const [reviewIssueSubmissionResponse, setReviewIssueSubmissionResponse] = useState(false)
  const [reviewedDuplicates, setReviewedDuplicates] = useState([])
  const [reviewedMissing, setReviewedMissing] = useState([])
  const [reviewedUnexpected, setReviewUnexpected] = useState([])
  const [awaitingSASSubmission, setAwaitingSASSubmission] = useState(false)
  const [reviewSASSubmission, setReviewSASSubmission] = useState(false)
  const [reviewSASSubmissionData, setreviewSASSubmissionData] = useState({})
  const [issuesAllSolved, setIssuesAllSolved] = useState(false)

  var body;
  var totalLocations = window.cache.RecData.length;
  var locationsOKToSubmit = 0;
  var locationsNotOKToSubmit = 0;

  for (var nn = 0; nn < window.cache.RecData.length; nn++) {
    if (window.cache.RecData[nn].Groups.length < 2) {
      if (window.cache.RecData[nn].Groups.length == 1 && window.cache.RecData[nn].SelectedGroupId > 0) {
        locationsOKToSubmit += 1;
      } else {
        locationsNotOKToSubmit += 1;
      }
    } else {
      if (window.cache.RecData[nn].AllScansMatchEachother) {
        locationsOKToSubmit += 1;
      } else {
        if (window.cache.RecData[nn].SelectedGroupId != null && window.cache.RecData[nn].SelectedGroupId > 0) {
          locationsOKToSubmit += 1;
        } else {
          locationsNotOKToSubmit += 1;
        }
      }
    }
  }

  const toggleScanFilterMode = (val, forceRefresh) => {

    if (val == filterScanMode && !forceRefresh) {
      return;
    }

    setFilterScanMode(val)
    var filtList = []

    if (val == 'All') {
      for (var nn = 0; nn < window.cache.RecData.length; nn++) {
        filtList.push(window.cache.RecData[nn])
      }
      setFilteredRecList(filtList);
    } else if (val == 'NoScans') {
      for (var nn = 0; nn < window.cache.RecData.length; nn++) {
        if (window.cache.RecData[nn].Groups == null || window.cache.RecData[nn].Groups.length == 0) {
          filtList.push(window.cache.RecData[nn])
        }
      }
      setFilteredRecList(filtList);
    } else if (val == 'OneScan') {
      for (var nn = 0; nn < window.cache.RecData.length; nn++) {
        if (window.cache.RecData[nn].Groups != null && window.cache.RecData[nn].Groups.length == 1) {
          filtList.push(window.cache.RecData[nn])
        }
      }
      setFilteredRecList(filtList);
    } else if (val == 'TwoScans') {
      for (var nn = 0; nn < window.cache.RecData.length; nn++) {
        if (window.cache.RecData[nn].Groups != null && window.cache.RecData[nn].Groups.length >= 2) {
          filtList.push(window.cache.RecData[nn])
        }
      }
      setFilteredRecList(filtList);
    } else if (val == 'TwoScansNoMatch') {
      for (var nn = 0; nn < window.cache.RecData.length; nn++) {
        if (window.cache.RecData[nn].Groups != null && window.cache.RecData[nn].Groups.length >= 2 && !window.cache.RecData[nn].AllScansMatchEachother) {
          filtList.push(window.cache.RecData[nn])
        }
      }
      setFilteredRecList(filtList);
    }

    window.state.endScreenFilterList = filtList;


  }

  const setStockTakeToReconcilliationMode = () => {

    if (window.cache.OngoingStocktakeStats.NotScanned != 0) {
      showYesNoQuestion("Proceed, even though some locations are unscanned?", () => doReconcilliationMode())
    } else {
      doReconcilliationMode();
    }

  }

  const doReconcilliationMode = () => {

    var request = {}
    request.Token = window.state.apiToken;

    postAndWait('MoveStocktakeIntoReconcilliationStatus', request)
    forceImmediateStatusRefresh()
    refresh()

  }

  const cancelStocktake = () => {
    showYesNoQuestion("This action will abort the current stocktake. Please select Cancel below if you are not 100% certain you wish to do this! Press OK if you are certain.", () => confirmCancel())
  }

  const confirmCancel = () => {

    var request = {}
    request.Token = window.state.apiToken;

    postAndWait('AbortOngoingStocktake', request)
    forceImmediateStatusRefresh()
    props.ReturnToMain()

  }

  const changeSelectedLocation = (locId) => {
    setSelectedRecData(locId)
    for (var nn = 0; nn < window.state.endScreenFilterList.length; nn++) {
      if (window.state.endScreenFilterList[nn].SASLocationId == locId) {
        setSelectedRecStats(JSON.parse(JSON.stringify(window.state.endScreenFilterList[nn])))
        return;
      }
    }
  }

  const isLocationOK = (locId) => {
    for (var nn = 0; nn < window.state.endScreenFilterList.length; nn++) {
      if (window.state.endScreenFilterList[nn].SASLocationId == locId) {
        if ((window.state.endScreenFilterList[nn].AllScansMatchEachother && window.state.endScreenFilterList[nn].Groups.length > 1) || window.state.endScreenFilterList[nn].SelectedGroupId > 0) {
          return true;
        }
      }
    }
    return false;
  }

  const selectAcceptableGroup = (groupId) => {

    var request = {}
    request.Token = window.state.apiToken;
    request.SAS3LocationId = selectedRecData;
    request.ChosenGroupId = groupId;

    postAndWait('ChooseAcceptableScanGroupForLocation', request)
    forceImmediateStatusRefresh()
    forceImmediateReconcilliationDataRefresh()
    toggleScanFilterMode(filterScanMode, true)
    changeSelectedLocation(request.SAS3LocationId)

  }

  const cancelGroupAcceptance = (groupId) => {

    var request = {}
    request.Token = window.state.apiToken;
    request.SAS3LocationId = selectedRecData;
    request.ChosenGroupId = groupId;

    postAndWait('CancelAcceptedScanGroupForLocation', request)
    forceImmediateStatusRefresh()
    forceImmediateReconcilliationDataRefresh()
    toggleScanFilterMode(filterScanMode, true)
    changeSelectedLocation(request.SAS3LocationId)

  }

  const requestRescan = () => {
    props.ShowUserSelectModal(rescanConfirmed);
  }

  const selectMissingItemLocation = (missingItem) => {
    window.state.missingItemToFind = missingItem;
    props.ShowMissingItemLocationModal(missingItemLocationConfirmed)
  }

  const missingItemLocationConfirmed = (obj) => {

    var request = {}
    request.Token = window.state.apiToken;
    request.StockUnitId = window.state.missingItemToFind.Id
    request.ExpectedSAS3LocationId = window.state.missingItemToFind.LocationId
    request.UserId = obj.Id;
    postAndWait('RequestMissingItemHunt', request);
    postAsync('CheckStocktakeForObviousIssues', request, reviewRequestCallback)

  }

  const rescanConfirmed = (obj) => {

    if (obj == null) {
      return;
    }

    var request = {}
    request.Token = window.state.apiToken;
    request.UserId = obj.Id != null && obj.Id != -1 ? obj.Id : null;
    request.SAS3LocationId = selectedRecData;

    postAndWait('RequestAdminRescan', request)
    forceImmediateStatusRefresh()
    forceImmediateReconcilliationDataRefresh()
    toggleScanFilterMode(filterScanMode, true)
    changeSelectedLocation(request.SAS3LocationId)

  }

  const submitForReview = () => {

    setAwaitingIssueSubmission(true)

    var request = {}
    request.Token = window.state.apiToken;

    postAsync('CheckStocktakeForObviousIssues', request, reviewRequestCallback)

  }

  const markItemLost = (id) => {

    var request = {}
    request.Token = window.state.apiToken;
    request.Id = id;
    postAndWait('MarkItemLost', request);
    postAsync('CheckStocktakeForObviousIssues', request, reviewRequestCallback)

  }

  const reviewRequestCallback = (resp) => {

    var dat = JSON.parse(resp.currentTarget.response);
    if (dat.Result) {
      if (dat.Data.Duplicates == null) {
        setReviewedDuplicates([]);
      } else {
        setReviewedDuplicates(dat.Data.Duplicates);
      }
      if (dat.Data.MissingStockUnits == null || dat.Data.MissingStockUnits.length == 0) {
        setReviewedMissing([]);
      } else {
        setReviewedMissing(dat.Data.MissingStockUnits);
        let missingOk = true;
        for (var nn = 0; nn < dat.Data.MissingStockUnits.length; nn++) {
          if (dat.Data.MissingStockUnits[nn].AlreadyMarkedLocation == null) {
            missingOk = false;
          }
        }
        if (dat.Data.Duplicates != null) {
          setIssuesAllSolved(false)
        } else {
          setIssuesAllSolved(missingOk)
        }
      }
      if (dat.Data.UnexpectedStockUnits == null || dat.Data.UnexpectedStockUnits.length == 0) {
        setReviewUnexpected([])
      } else {
        setReviewUnexpected(dat.Data.UnexpectedStockUnits)
      }
      setReviewIssueSubmissionResponse(true)
    } else {
      showError(dat.ErrorMessage)
    }

    setAwaitingIssueSubmission(false)

  }

  const submitStocktakeToSAS = () => {

    setReviewIssueSubmissionResponse(false)
    setAwaitingSASSubmission(true)

    var request = {}
    request.Token = window.state.apiToken;

    postAsync('SubmitStocktake', request, submitToSASCallback)

  }

  const submitToSASCallback = (resp) => {
    var dat = JSON.parse(resp.currentTarget.response);
    if (dat.Result) {
      setReviewSASSubmission(true)
      setreviewSASSubmissionData(dat.Data)
    } else {
      showError(dat.ErrorMessage)
    }

    setAwaitingSASSubmission(false)
  }

  const returnToReconcile = (resp) => {
    setReviewIssueSubmissionResponse(false)
  }

  const refresh = () => {
    //changeSelectedLocation(selectedRecData)
    forceUpdate();
  }

  useEffect(() => {

    if (window.state.endScreenFilterList.length == 0) {
      toggleScanFilterMode('All', true)
    }

    return () => {
      window.state.unsubscribeFromEvent('Finish', SubscriptionEvent.ReconcilliationDataUpdated);
    }

  }, [])

  //window.state.subscribeToEvent('Finish', SubscriptionEvent.ReconcilliationDataUpdated, refresh);

  if (window.cache.StocktakeStatus == 1) {
    //Stocktake is still in ongoing scan mode
    body =
      <div>
        <table>
          <tr>
            <td style={{ verticalAlign: "top" }}>
              <h2>Assigned location stats</h2>
              <table cellSpacing={0} cellPadding={8}>
                <tr class="EndStockTakeSummaryHeader">
                  <th>User</th>
                  <th>Assigned</th>
                  <th>Scanned</th>
                  <th>Unscanned</th>
                </tr>
                {window.cache.AllocatedScanStats.map((a) => <tr><td class="EndStockTakeUser">{a.Username}</td><td class="EndStockTakeNumber">{a.Total}</td><td class="EndStockTakeNumber">{a.Scanned}</td><td class="EndStockTakeNumber">{a.Unscanned}</td></tr>)}
              </table>
            </td>
            <td>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </td>
            <td style={{ verticalAlign: "top" }}>
              <Stats />
            </td>
          </tr>
        </table>
        <p>&nbsp;</p>
        <hr />
        {window.cache.OngoingStocktakeStats.NotScanned != 0 ?
          <p style={{ color: "#F78888" }}>There are locations with zero scans. You *can* proceed, but you will be <b>OBLIGED</b> to assign 'admin rescans' to these locations.
          <br/>It is recommended that you do not proceed until every location has been scanned once.</p> : <></>}
        <table>
          <tbody>
            <tr>
              <td>
                <p style={{ maxWidth: "650px" }}>The current stocktake is <span style={{ color: "#44EE44" }}>OPEN.</span> Scans are accepted from all locations. Next up is to review scanned data. Then scans will only be accepted for locations you have requested be rescanned</p>
                <p><TextButton IsSuccess={true} Caption="Move to review stage" Callback={setStockTakeToReconcilliationMode} Width={300} /></p>
              </td>
              <td style={{ paddingLeft: "80px" }}>
                <p style={{ maxWidth: "650px" }}>You can also abort the ongoing stocktake if it has been started in error. This will mark the stocktake as aborted in the database, but all activity that happened will still be recorded</p>
                <p><TextButton IsCancel={true} Caption="Abort ongoing stocktake" Callback={cancelStocktake} Width={300} /></p>
              </td>
            </tr>
          </tbody>
        </table>


      </div>

  } else if (window.cache.StocktakeStatus == 2) {
    if (!reviewIssueSubmissionResponse && !reviewSASSubmission && !awaitingIssueSubmission && !awaitingSASSubmission) {
      body =
        <table>
          <tr>
            <td style={{ verticalAlign: "top" }}>
              <h2 style={{ lineHeight: "10px" }}>Locations in stocktake</h2>
              <div>
                <div className="LocationFilterEnd">
                  <span id='typeSpan_1' onClick={() => toggleScanFilterMode('All')} className={filterScanMode == 'All' ? "ToggleItemEnabledSmall TypeSpanSmall" : "ToggleItemDisabledSmall TypeSpanSmall"}>All</span>
                  <span id='typeSpan_2' onClick={() => toggleScanFilterMode('NoScans')} className={filterScanMode == 'NoScans' ? "ToggleItemEnabledSmall TypeSpanSmall" : "ToggleItemDisabledSmall TypeSpanSmall"}>No scans</span>
                  <span id='typeSpan_3' onClick={() => toggleScanFilterMode('OneScan')} className={filterScanMode == 'OneScan' ? "ToggleItemEnabledSmall TypeSpanSmall" : "ToggleItemDisabledSmall TypeSpanSmall"}>1 scan</span>
                  <span id='typeSpan_4' onClick={() => toggleScanFilterMode('TwoScans')} className={filterScanMode == 'TwoScans' ? "ToggleItemEnabledSmall TypeSpanSmall" : "ToggleItemDisabledSmall TypeSpanSmall"}>2+ scans</span>
                  <span id='typeSpan_4' onClick={() => toggleScanFilterMode('TwoScansNoMatch')} className={filterScanMode == 'TwoScansNoMatch' ? "ToggleItemEnabledSmall TypeSpanSmall" : "ToggleItemDisabledSmall TypeSpanSmall"}>2+ diff.</span>
                </div>
                <div className="ReconcileList">
                  {filteredRecList.map((f) => <div key={f.SASLocationId} onClick={() => changeSelectedLocation(f.SASLocationId)} className={f.Groups.length == 0 ? (selectedRecData == f.SASLocationId ? "ReconNoScansSelected" : "ReconNoScans")
                    : f.Groups.length == 1 ? (selectedRecData == f.SASLocationId ? "ReconOneScanSelected" : "ReconOneScan") :
                      (selectedRecData == f.SASLocationId ? "ReconTwoScansSelected" : "ReconTwoScans")}><b>{f.Name}</b>
                    {isLocationOK(f.SASLocationId) ? <div style={{ marginTop: "-16px" }}><img src="tick.png" width="20px" height="20px" /></div> : <div style={{ color: "#F14444", marginTop: "-22px", fontSize: "20px" }}><b>X</b></div>}</div>)}
                </div>
                <div>
                  <h2 style={{ lineHeight: "1px" }}>Summary</h2>
                  <table>
                    <tr>
                      <td>Total Locations:</td><td>{totalLocations}</td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td><td>OK to submit:</td><td>{locationsOKToSubmit}</td>
                    </tr>
                    <tr>
                      <td>Not OK to submit:</td><td>{locationsNotOKToSubmit}</td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td><td colSpan="3">{locationsOKToSubmit == totalLocations ? <TextButton IsSuccess={true} Callback={submitForReview} Caption="Submit for review" /> : <></>}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </td>
            <td>
              &nbsp;&nbsp;
            </td>
            <td style={{ verticalAlign: "top", borderLeft: "solid 1px #555555", paddingLeft: "9px" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h2 style={{ lineHeight: "10px" }}>{selectedRecStats == null ? '(No selection)' : selectedRecStats.Name}</h2>
                {selectedRecStats != null && selectedRecStats.Groups.length >= 2 && selectedRecStats.AllScansMatchEachother ?
                  <span className="GoodScanArea">All match!</span> : selectedRecStats != null && selectedRecStats.SelectedGroupId > 0 ? <span className="GoodScanArea">Group chosen!</span> : <></>}
                {selectedRecStats != null && selectedRecStats.RescanRequestOutstanding == false ? <div style={{ paddingTop: "5px", paddingLeft: "30px" }}><TextButton Caption="Get rescan" Width="85px" Callback={() => requestRescan()} /></div> : <></>}
              </div>
              {selectedRecStats == null ? <></> :
                <div>
                  <div style={{ overflowY: "auto", height: "390px", maxHeight: "390px", minHeight: "390px" }}>
                    {selectedRecStats.Groups.map((g) => <div key={g.Id}><p>{g.ScanGroupUsername}</p>{g.WasEmpty ? <div className="EmptyLocationAtFinishHolder"><p className="EmptyLocationAtFinish">LOCATION WAS EMPTY!</p></div> :
                      <table cellSpacing={0} cellPadding={8} className="EndStockTakeScanGroupTable"><tr className="EndStockTakeSummaryHeader"><th>Qty</th><th>Description</th><th>Serial</th></tr>
                        {g.Scans.map((s) => <tr><td>{s.Qty}</td><td>{s.UnitDetails.ProductDescription}</td><td>{s.UnitDetails.SerialNo}</td></tr>)}</table>}
                      {selectedRecStats.AllScansMatchEachother ? selectedRecStats.Groups.length == 1 ? selectedRecStats.SelectedGroupId > 0 ? <div style={{ display: "flex", flexDirection: "row" }}><div className="GroupSelected">Single scan permitted!</div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<TextButton Callback={() => cancelGroupAcceptance(g.GroupId)} Caption="Cancel permission" Width="150px" /></div> : <TextButton Callback={() => selectAcceptableGroup(g.GroupId)} Caption="Accept single scan" Width="200px" /> : <></> :
                        selectedRecStats.SelectedGroupId != null && selectedRecStats.SelectedGroupId > 0 ?
                          (selectedRecStats.SelectedGroupId == g.GroupId ? <div style={{ display: "flex", flexDirection: "row" }}><div className="GroupSelected">Selected as correct!</div>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<TextButton Callback={() => cancelGroupAcceptance(g.GroupId)} Caption="Cancel acceptance" Width="150px" /></div> : <div className="GroupDiscarded">To be discarded...</div>) :
                          <TextButton Callback={() => selectAcceptableGroup(g.GroupId)} Caption="Accept these scans" Width="200px" />}</div>)}
                  </div>
                </div>

              }
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>{selectedRecStats == null ? <></> : selectedRecStats.RescanRequestOutstanding ? <span className="GroupSelected">A rescan request for this location is outstanding</span> : <></>}</td>
          </tr>
        </table>
    } else if (awaitingIssueSubmission) {
      body = <div style={{ marginLeft: "auto", marginRight: "auto" }}><div style={{ marginLeft: "auto", marginRight: "auto" }}><p>&nbsp;</p><p>&nbsp;</p><img src="Spinner.gif" width="130px" height="130px" /></div><div>Waiting for server to analyse,<br />please wait...</div></div>
    } else if (reviewIssueSubmissionResponse) {
      body =
        <div class="flex">
          <div>
            {reviewedMissing.length > 0 ?
              <div>
                <div style={{ background: "#444444FF", borderRadius: "3px", padding: "10px", fontSize: "20px" }}>
                  Items missing from locations ({reviewedMissing.length})&nbsp;&nbsp;&nbsp;
                  <span id="missingSpan" onClick={() => showMissing()} style={{ cursor: "pointer", color: "rgb(74 250 73)", textDecoration: "underline" }}>Show</span>
                </div>
                <div id="missingDiv" style={{ visibility: "collapse", maxHeight: "0px", paddingLeft: "20px" }}>
                  <h2>Missing Stockunits ({reviewedMissing.length} units)</h2>
                  <div style={{ marginTop: "-12px", paddingBottom: "4px", paddingLeft: "4px", color: "#FF8888FF", fontWeight: "500" }}>
                    The following stock units are supposed to be in the locations scanned in this stocktake, but have not been scanned.
                    <br />You should either mark them as lost, or begin a missing item hunt
                  </div>
                  <div className="DuplicateItemScroller">
                    {reviewedMissing.map((m) =>
                      <div className="DupStockUnitDiv flex">
                        <div className="DuplicateDescriptionDiv">
                          {m.PartNo}&nbsp;&nbsp;&nbsp;{m.Description}<br />
                          <span>Serial: </span>{m.SerialNo}&nbsp;&nbsp;&nbsp;<span>Expected Location: </span>{m.Location}
                        </div>
                        {m.AlreadyMarkedLocation == null && m.ItemHuntOngoing === false ?
                          <div className="flex" style={{ minWidth: "250px" }}>
                            <TextButton Callback={() => markItemLost(m.Id)} Caption="It's lost" />&nbsp;&nbsp;
                            <TextButton Callback={() => selectMissingItemLocation(m)} Caption="Start item hunt" />
                          </div> :
                          m.ItemHuntOngoing ?
                            <div style={{ marginTop: "8px", color: "#00F1FF" }}>Missing item hunt ongoing</div>
                            :
                            <div>Location: {m.AlreadyMarkedLocation}</div>}
                      </div>)}
                  </div>
                </div>
              </div>
              :
              <div>
                <div style={{ background: "#444444FF", borderRadius: "3px", padding: "10px", fontSize: "20px", marginBottom: "18px" }}>
                  Items missing from locations (0)&nbsp;&nbsp;&nbsp;
                  <span id="missingSpan" style={{ cursor: "pointer", color: "rgb(170 170 170)", textDecoration: "underline" }}>Nothing to show</span>
                </div>
              </div>
            }
            {reviewedDuplicates.length > 0 ?
              <div>
                <div style={{ background: "#444444FF", borderRadius: "3px", padding: "10px", fontSize: "20px" }}>
                  Items duplicated across scans ({reviewedDuplicates.length})&nbsp;&nbsp;&nbsp;
                  <span id="duplicateSpan" onClick={() => showDuplicate()} style={{ cursor: "pointer", color: "rgb(74 250 73)", textDecoration: "underline" }}>Show</span>
                </div>
                <div id="duplicateDiv" style={{ visibility: "collapse", maxHeight: "0px", paddingLeft: "20px" }}>
                  <h2>Duplicate Stockunits ({reviewedDuplicates.length} found)</h2>
                  <div style={{ marginTop: "-12px", paddingBottom: "4px", paddingLeft: "4px", color: "#FF8888FF", fontWeight: "500" }}>Stock units cannot be in two locations, unless one of the locations is a temporary holding area such as the Build Room<br />At least one set of scans is incorrect, please check</div>
                  <div className="DuplicateItemScroller">
                    {reviewedDuplicates.map((d) => <div className="DupStockUnitDiv" style={{ display: "flex", flexDirection: "row" }}>
                      <div className="DuplicateDescriptionDiv">{d.PartNo}&nbsp;&nbsp;&nbsp;{d.ProductDescription}<br /><span>Serial: </span>{d.SerialNo}</div>
                      <div><span class="DuplicateLocList">Found in:</span>{d.Locations.map((l) => <span class="DuplicateLoc">{l.Name}</span>)}</div>
                    </div>)}
                  </div>
                </div>
              </div>
              :
              <div>
                <div style={{ background: "#444444FF", borderRadius: "3px", padding: "10px", fontSize: "20px", marginBottom: "18px" }}>
                  Items duplicated across scans (0)&nbsp;&nbsp;&nbsp;
                  <span id="missingSpan" style={{ cursor: "pointer", color: "rgb(170 170 170)", textDecoration: "underline" }}>Nothing to show</span>
                </div>
              </div>
            }
            {reviewedUnexpected.length > 0 ?
              <div>
                <div style={{ background: "#444444FF", borderRadius: "3px", padding: "10px", fontSize: "20px" }}>
                  Items scanned that were not expected ({reviewedUnexpected.length})&nbsp;&nbsp;&nbsp;
                  <span id="unexpectedSpan" onClick={() => showUnexpected()} style={{ cursor: "pointer", color: "rgb(74 250 73)", textDecoration: "underline" }}>Show</span>
                </div>
                <div id="unexpectedDiv" style={{ visibility: "collapse", maxHeight: "0px", paddingLeft: "20px" }}>
                  <h2>Unexpected Stockunits ({reviewedUnexpected.length} found)</h2>
                  <div style={{ marginTop: "-12px", paddingBottom: "4px", paddingLeft: "4px", color: "#88DDFFFF", fontWeight: "500" }}>These stock units were meant to be in locations that were not in this stocktake. Nothing is neccessarily wrong here: <br />if they have moved, the stocktake will adjust the database, but you should review</div>
                  <div className="DuplicateItemScroller">
                    {reviewedUnexpected.map((d) => <div className="DupStockUnitDiv" style={{ display: "flex", flexDirection: "row" }}>
                      <div className="DuplicateDescriptionDiv">{d.PartNo}&nbsp;&nbsp;&nbsp;{d.ProductDescription}<br /><span>Serial: </span>{d.SerialNo}</div>
                      {/*<div><span class="DuplicateLocList">Found in:</span>{d.Locations.map((l) => <span class="DuplicateLoc">{l.Name}</span>)}</div>*/}
                    </div>)}
                  </div>
                </div>
              </div>
              :
              <div>
                <div style={{ background: "#444444FF", borderRadius: "3px", padding: "10px", fontSize: "20px", marginBottom: "18px" }}>
                  Items scanned that were not expected (0)&nbsp;&nbsp;&nbsp;
                  <span id="missingSpan" style={{ cursor: "pointer", color: "rgb(170 170 170)", textDecoration: "underline" }}>Nothing to show</span>
                </div>
              </div>
            }
          </div>
          <div style={{ paddingLeft: "20px" }}>
            {reviewedDuplicates.length == 0 && reviewedMissing.length == 0 ?
              <div>
                <h2>This stocktake can be submitted!</h2>
                <p>&nbsp;</p>
                <p>There are no obvious issues with this stocktake (no duplicate stock units across locations, no stock unaccounted for in SAS, all values are valid to submit).</p>
                <p>If you are confident in the scans and numbers you intend to submit, you may click the below button.</p>
                <p className="SubmitWarning">This will end the stocktake, and all stock-movements required to set SAS to this new truth will be executed!</p>
                <TextButton Width={320} Caption="Submit this to SAS" Callback={submitStocktakeToSAS} IsSuccess={true} />
                <p>&nbsp;</p>
                <p>Alternatively, if you want to double check something:</p>
                <TextButton Width={320} IsCancel={true} Caption="Return to previous screen" Callback={returnToReconcile} />
              </div>
              :
              <div>
                {issuesAllSolved ?
                  <>
                    <p>Any issues that were present have now been resolved: no duplicates are now detected and all missing stock has been either found or marked as lost</p>
                    <div className="flex" style={{ flexDirection: "row-reverse" }}>
                      <div style={{ paddingLeft: "20px" }}><TextButton Width={320} IsSuccess={true} Caption="Submit this to SAS" Callback={submitStocktakeToSAS} /></div>
                      <div><TextButton Width={320} IsCancel={true} Caption="Return to previous screen" Callback={returnToReconcile} /></div>
                    </div>
                  </>
                  :
                    <div><TextButton Width={320} IsCancel={true} Caption="Return to previous screen" Callback={returnToReconcile} /></div>
                  }
              </div>
              }
          </div>
      </div>
    } else if (awaitingSASSubmission) {
      body = <div style={{ marginLeft: "auto", marginRight: "auto" }}><div style={{ marginLeft: "auto", marginRight: "auto" }}><p>&nbsp;</p><p>&nbsp;</p><img src="Spinner.gif" width="130px" height="130px" /></div><div>Waiting for SAS to accept and process,<br />please wait...</div></div>
    } else if (reviewSASSubmission) {
      body = <div>
        <h2>Stocktake submitted!</h2>
        <table>
          <tr>
            <td style={{ verticalAlign: "top" }}>
              <h2>Summary of activity</h2>
              <div className="flex">
                <div className="SummaryStat">Exsiting stock units where they ought to be: </div>
                <div className="SummaryStatValue">{reviewSASSubmissionData.StockUnitsWhereTheyShouldBe}</div>
              </div>
              <div className="flex">
                <div className="SummaryStat">Existing stock units moved to new location: </div>
                <div className="SummaryStatValue">{reviewSASSubmissionData.StockUnitsMoved}</div>
              </div>
              <hr />
              <div className="flex">
                <div className="SummaryStat">Stock units we needed to create: </div>
                <div className="SummaryStatValue">{reviewSASSubmissionData.StockUnitsCreated}</div>
              </div>
              <hr />
              <div className="flex">
                <div className="SummaryStat">PO Item sent but existing stock unit found: </div>
                <div className="SummaryStatValue">{reviewSASSubmissionData.StockUnitsAlreadyExistedWhenGivenAPOItem}</div>
              </div>
              <div className="flex">
                <div className="SummaryStat">PO Units created to link to stock units: </div>
                <div className="SummaryStatValue">{reviewSASSubmissionData.PurchaseOrderItemUnitsCreated}</div>
              </div>
              <div className="flex">
                <div className="SummaryStat">SO Units created to link to stock units: </div>
                <div className="SummaryStatValue">{reviewSASSubmissionData.SalesOrderItemUnitsCreated}</div>
              </div>
              <div className="flex">
                <div className="SummaryStat">BR Units created to link to stock units: </div>
                <div className="SummaryStatValue">{reviewSASSubmissionData.BuildRequestItemUnitsCreated}</div>
              </div>
              <p>&nbsp;</p>
            </td>
            <td style={{ verticalAlign: "top" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </td>
            <td style={{ verticalAlign: "top" }}>
              <h2>Work not completed</h2>
              <p>(Sometimes, due to partial states, we cannot reliably make <span style={{ color: "#22EEEE", fontWeight: "500" }}>Build Request</span> units. Anything you need to do manually is listed here)</p>
            </td>
          </tr>
        </table>
      </div>
    }
  }

  return (
    <div style={{ padding: "12px" }}>{body}</div>
  )

}

function showMissing() {
  if (document.getElementById('missingDiv').style.visibility == 'collapse') {
    document.getElementById('missingDiv').style.visibility = 'visible'
    document.getElementById('missingDiv').style.maxHeight = '50vh'
    document.getElementById('missingSpan').innerText = "Hide"
    document.getElementById('missingSpan').style.color = "#FF0000FF"
  } else {
    document.getElementById('missingDiv').style.visibility = 'collapse'
    document.getElementById('missingDiv').style.maxHeight = '0px'
    document.getElementById('missingSpan').innerText = "Show"
    document.getElementById('missingSpan').style.color = "rgb(74 250 73)"
  }
}

function showDuplicate() {
  if (document.getElementById('duplicateDiv').style.visibility == 'collapse') {
    document.getElementById('duplicateDiv').style.visibility = 'visible'
    document.getElementById('duplicateDiv').style.maxHeight = '50vh'
    document.getElementById('duplicateSpan').innerText = "Hide"
    document.getElementById('duplicateSpan').style.color = "#FF0000FF"
  } else {
    document.getElementById('duplicateDiv').style.visibility = 'collapse'
    document.getElementById('duplicateDiv').style.maxHeight = '0px'
    document.getElementById('duplicateSpan').innerText = "Show"
    document.getElementById('duplicateSpan').style.color = "rgb(74 250 73)"
  }
}

function showUnexpected() {
  if (document.getElementById('unexpectedDiv').style.visibility == 'collapse') {
    document.getElementById('unexpectedDiv').style.visibility = 'visible'
    document.getElementById('unexpectedDiv').style.maxHeight = '50vh'
    document.getElementById('unexpectedSpan').innerText = "Hide"
    document.getElementById('unexpectedSpan').style.color = "#FF0000FF"
  } else {
    document.getElementById('unexpectedDiv').style.visibility = 'collapse'
    document.getElementById('unexpectedDiv').style.maxHeight = '0px'
    document.getElementById('unexpectedSpan').innerText = "Show"
    document.getElementById('unexpectedSpan').style.color = "rgb(74 250 73)"
  }
}

export default FinishStocktake;