import React, { useState, useReducer, useEffect } from 'react';
import '../stylesheet/styles.css';

function NumberPad(props) {

  const [enteredVal, setEnteredVal] = useState('0')

  const buttonClick = (stringNum) => {
    var val = enteredVal
    if (val == '0') {
      val = ''
    }
    val += stringNum
    setEnteredVal(val)
  }

  const delClick = () => {
    var val = enteredVal
    if (val.length == 1) {
      val = '0'
    } else {
      val = val.substring(0, val.length - 1);
    }
    setEnteredVal(val)    
  }

  const submitClick = () => {
    props.SubmitCallback(enteredVal)
    if (props.ResetOnSubmit != null && props.ResetOnSubmit) {
      setEnteredVal(0)
    }
  }

  return (


    <>
      <table width="300">
        <tr>
          <td colSpan={3}>
            <input className="DigitalBox" id="number" value={enteredVal} />
          </td>
        </tr>
        <tr>
          <td width="33%" className="NumButtonHolder" onClick={() => buttonClick('7')}>
            <span className="NumButton">7</span>
          </td>
          <td width="33%" className="NumButtonHolder" onClick={() => buttonClick('8')}>
            <span className="NumButton">8</span>
          </td>
          <td width="33%" className="NumButtonHolder" onClick={() => buttonClick('9')}>
            <span className="NumButton">9</span>
          </td>
        </tr>
        <tr>
          <td className="NumButtonHolder" onClick={() => buttonClick('4')}>
            <span className="NumButton">4</span>
          </td>
          <td className="NumButtonHolder" onClick={() => buttonClick('5')}>
            <span className="NumButton">5</span>
          </td>
          <td className="NumButtonHolder" onClick={() => buttonClick('6')}>
            <span className="NumButton">6</span>
          </td>
        </tr>
        <tr>
          <td className="NumButtonHolder" onClick={() => buttonClick('1')}>
            <span className="NumButton">1</span>
          </td>
          <td className="NumButtonHolder" onClick={() => buttonClick('2')}>
            <span className="NumButton">2</span>
          </td>
          <td className="NumButtonHolder" onClick={() => buttonClick('3')}>
            <span className="NumButton">3</span>
          </td>
        </tr>
        <tr>
          <td className="NumButtonHolder" onClick={() => delClick() }>
            <img width="60px" height="48px" src="backspace.png" />
          </td>
          <td className="NumButtonHolder" onClick={() => buttonClick('0')}>
            <span className="NumButton">0</span>
          </td>
          <td className="NumButtonHolder" onClick={() => submitClick()}>
            <img width="60px" height="48px" src="numenter.png" />
          </td>
        </tr>
      </table>


    </>



  )

}

export default NumberPad;